// helper function converts date to string for cookies
export function dateToCookieString(d) {
  function d2(n) { return n < 10 ? '0' + n : n; }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return '' +
    days[d.getUTCDay()] + ', ' +
    d2(d.getUTCDate()) + '-' +
    months[d.getUTCMonth()] + '-' +
    d.getUTCFullYear() + ' ' +
    d2(d.getUTCHours()) + ':' +
    d2(d.getUTCMinutes()) + ':' +
    d2(d.getUTCSeconds()) + ' GMT';
}