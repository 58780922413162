const Cookies = require('js-cookie');
import { dateToCookieString } from '../../util/cookies';

const removeBtn = document.querySelector('.remove');
const notificationID = document.querySelector('.notification-div').dataset['siteNotification'];
const countdownSpan = document.querySelector('span.countdown');
const groundedLink = document.querySelector('#grounded-notification a.notification-link');

// check if notification should load
function loadNotificationHandler() {
  if (!Cookies.get(`notification-${notificationID}`)) {
    // set active class to show notification
    const notificationDiv = document.querySelector('#notification');
    notificationDiv.classList.add('active');
  } 
  return;
}

// closes notification and sets cookie
function closeNotificationHandler(e) {
  
  // don't follow default if link is 
  if (e.target.closest("a") === groundedLink) {
    // go to grounded url
  } else {
    e.preventDefault();
  }

  let cookieExpireHrs = document.querySelector(`div[data-site-notification="${notificationID}"]`).dataset['cookieExpire'];
  // set to 24 if cookieExpireHrs is null
  cookieExpireHrs = parseInt(cookieExpireHrs) ? parseInt(cookieExpireHrs) : 24;

  //set cookie
  let cookieExpire = new Date();
  cookieExpire.setHours(cookieExpire.getHours() + cookieExpireHrs);
  let cookieString = dateToCookieString(cookieExpire);
  document.cookie = `notification-${notificationID}=hide; expires=${cookieString}; path=/`;
  
  //change class to hide notification
  e.target.closest('div.active').classList.remove('active');
  return;
}

function loadCountdown(countdownObj) {
  let expireDate = new Date(countdownObj.dataset['countdownExpire']).getTime();

  function timer() {
    const nowDate = new Date().getTime();
    let dateDifference = expireDate - nowDate;
    if (dateDifference < 0) {
      countdownObj.innerHTML = `0 days 0 hours 0 min`;
      return;
    }

    let days = Math.floor(dateDifference / (1000*60*60*24));
    let hours = Math.floor(dateDifference % (1000*60*60*24) / (1000*60*60));
    let minutes = Math.floor(dateDifference % (1000*60*60)/ (1000*60));

    let dayLabel = ngettext("day", "days", days);
    let hourLabel = ngettext("hour", "hours", hours);
    let minLabel = gettext("min");
    countdownObj.innerHTML = `${days} ${dayLabel} ${hours} ${hourLabel} ${minutes} ${minLabel}`;
    return true;
  }

  timer();
  setInterval(timer, 1000);
  
}



// event handlers
document.addEventListener("DOMContentLoaded", loadNotificationHandler, {once: true});
removeBtn.addEventListener("click", closeNotificationHandler, {once: true});
// load countdown functionality
if (countdownSpan) {
  loadCountdown(countdownSpan);
}
if (groundedLink) {
  groundedLink.addEventListener('click', closeNotificationHandler);
}




